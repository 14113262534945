import React, { FC, forwardRef, useRef } from 'react';
import './HotelRoomsSearch.scss';
import HotelPeople from '../../../common/HotelSearchForm/components/HotelPeople/HotelPeople';
import useSearchForm from '../../../common/HotelSearchForm/hooks/useSearchForm';
import { useHistory, useParams } from 'react-router-dom';
import AppButton from '../../../../../components/ui/components/Buttons/AppButton/AppButton';
import FieldDatepicker from '../../../../../components/ui/forms/fields/FieldDatepicker/FieldDatepicker';
import IconArrowBottom from '../../../../../components/ui/icons/icons/IconArrowBottom';
import { formatDate } from '../../../../../helpers/date';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../../store';
import CustomDatepickerField from './components/CustomDatepickerField/CustomDatepickerField';
import { useTranslation } from "react-i18next";

interface Props {
    isOneOf: boolean;
}

const HotelRoomsSearch: FC<Props> = ({isOneOf}) => {
    const {endDate, startDate, changeDates, query, guestsParams, changeGuestForm, selectChildren, changeKidsAge, excludeDates} = useSearchForm(isOneOf);

    const {focusOnHotelSearchForm} = useSelector((state: AppState) => state.rooms);
    const [t] = useTranslation();

    const history = useHistory();
    const {alias = ''} = useParams<{ alias: string }>();

    const calendarRef = useRef(null);

    const changeDate = (...params) => {
        if(params[0].length && params[0][0] && params[0][1]){
            calendarRef.current.setOpen(false);
        }
        // @ts-ignore
        changeDates(...params)
    }

    // @ts-ignore
    const CustomInput = forwardRef(({value, onClick}, ref) => {
        const rangeStartString = startDate ? formatDate(new Date(startDate), 'EEEEEE, d MMMM') : '';
        const rangeEndString = endDate ? formatDate(new Date(endDate), 'EEEEEE, d MMMM') : '';

        return (
            <CustomDatepickerField
                start={rangeStartString}
                end={rangeEndString}
                onClickStart={onClick}
                onClickEnd={onClick}
            />
        );
    });

    const searchSubmit = () => {
        if (!startDate) {
            calendarRef.current.setOpen(true);
        } else if (!endDate) {
            calendarRef.current.setOpen(true);
        } else {
            history.push(`/hotels/${alias}${query}`);
        }

        // коли спрацьовує пуш справьовує getInitialProps
        // тому не потрібно робити запрос
        // dispatch(getHotelsThunk(query))
    };

    return (
        <>
            {focusOnHotelSearchForm && <div className={'hotel-rooms-search__focus-background'}/>}

            <div>
                {/*<h3 className="hotel-rooms-search-title">Наявність вільних місць</h3>*/}
                <div className={`hotel-rooms-search ${focusOnHotelSearchForm ? 'hotel-rooms-search--focused' : ''}`}>
                    <div className="hotel-rooms-search__date-pickers">
                        <FieldDatepicker
                            //@ts-ignore
                            ref={calendarRef}
                            name="start"
                            size="large"
                            // inline
                            selectsRange
                            minDate={new Date()}
                            // selectsEnd={false}
                            // selectsStart={false}
                            monthsShown={1}
                            shouldCloseOnSelect={false}
                            dateFormat="dd.MM.yyyy"
                            selected={null}
                            onChange={changeDate}
                            startDate={startDate}
                            endDate={endDate}
                            customInput={<CustomInput/>}
                            selectsDisabledDaysInRange
                            excludeDates={excludeDates}
                        >
                            {isOneOf && <div className="hotel-rooms-search__notify">
                                {t('min_days_book')}
                            </div>}
                        </FieldDatepicker>
                    </div>
                    <div className="hotel-rooms-search__people">
                        <label className="hotel-rooms-search__people-label">
                            {t("Гості")}
                            <HotelPeople
                                guestsParams={guestsParams}
                                arrow={<IconArrowBottom/>}
                                guestsMethods={{
                                    changeGuestForm,
                                    selectChildren,
                                    changeKidsAge,
                                }}
                            />
                        </label>
                        <div className="hotel-rooms-search__button">
                            <AppButton onClick={searchSubmit} size="middle">
                                {t("Змінити пошук")}
                            </AppButton>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default HotelRoomsSearch;
