import { useLocation } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';
import { format } from 'date-fns';
import differenceInDays from 'date-fns/differenceInDays';

import { getUrlParams, removePlusSignsFromString } from '../../../../../helpers/url';
import hotelsAPI from '../../../../../api/hotelsAPI';
import { DATE_FORMAT_SERVER, isBronui } from '../../../../../config';
import { _formatDate, formatDate } from '../../../../../helpers/date';
import useDebounce from '../../../../../hooks/useDebounce';
import { SearchFormPlaceType } from '../../../../../types/hotel';
import { fillPlaceObject, formatPlacesToOptions } from './useSearchFormHelpers';
import dayjs from "dayjs";

interface GuestsParams {
    adults: number;
    rooms: number;
    kids: Array<number | string>
}

type DateType = Date | null

function useSearchForm(isOneOf) {
    const {search} = useLocation();
    const today = new Date();
    let tomorrow = new Date();

    const minDaysBook = isOneOf ? 2 : 1;

    tomorrow.setDate(tomorrow.getDate() + minDaysBook);

    // TODO AFTER URL CHANGE, CHANGE INPUT DATA
    let {
        place_id = '',
        hotel_id,
        place_name = '',
        adults = 2,
        arrival_date = formatDate(today, 'yyyy-MM-dd'),
        departure_date = formatDate(tomorrow, 'yyyy-MM-dd'),
        rooms = 1,
        order_by,
        latitude = '',
        longitude = '',
        show_all = false
    } = getUrlParams(search) as any;

    const defaultKidsAge = 7;
    const defaultArrival = arrival_date ? new Date(arrival_date) : null;
    const defaultDeparture = departure_date ? new Date(departure_date) : null;

    const [selectedPlaceOption, setSelectedPlaceOption] = useState(
        fillPlaceObject(hotel_id, place_id, place_name, latitude, longitude)
    );

    const [defaultOptions, setDefaultOptions] = useState([]);

    const [searchParams, setSearchParams] = useState<URLSearchParams>(new URLSearchParams(search));
    //const searchParams = useRef<URLSearchParams>(new URLSearchParams(search)).current;

    // дефолтні дані для range datepicker
    const [startDate, setStartDate] = useState<DateType>(defaultArrival);
    const [endDate, setEndDate] = useState<DateType>(defaultDeparture);

    const [guestsParams, setPeopleParams] = useState<GuestsParams>({
        adults: Number(adults),
        rooms: Number(rooms),
        kids: searchParams.getAll('kids[]')
    });

    // Якщо міняється url адреса тодіберу нові дані для відображення і функціоналу
    useEffect(() => {
        setStartDate(defaultArrival);
        setEndDate(defaultDeparture);

        setSelectedPlaceOption(fillPlaceObject(hotel_id, place_id, place_name, latitude, longitude));

        setPeopleParams({
            adults: Number(adults),
            rooms: Number(rooms),
            kids: searchParams.getAll('kids[]')
        });
    }, [search]);

    const arrivalDate = format(new Date(startDate), 'yyyy-MM-dd');
    const departureDate = format(new Date(endDate), 'yyyy-MM-dd');

    /* Using by every render */
    const _setParams = (paramsObject = searchParams) => {
        //Todo: use qs
        paramsObject.set('place_name', selectedPlaceOption.label);

        paramsObject.set(
            selectedPlaceOption?.place?.type === 'hotel' ? 'hotel_id' : 'place_id',
            selectedPlaceOption.value
        );

        paramsObject.delete(selectedPlaceOption?.place?.type === 'hotel' ? 'place_id' : 'hotel_id');
        paramsObject.set('latitude', selectedPlaceOption?.place?.coordinates?.latitude);
        paramsObject.set('longitude', selectedPlaceOption?.place?.coordinates?.longitude);

        paramsObject.set('arrival_date', arrivalDate);
        paramsObject.set('departure_date', departureDate);
        paramsObject.set('adults', String(guestsParams.adults));

        if (order_by) paramsObject.set('order_by', order_by);

        paramsObject.set('rooms', String(guestsParams.rooms));
        paramsObject.delete('kids[]');
        guestsParams.kids.forEach(item => {
            paramsObject.append('kids[]', String(item));
        })
    }

    _setParams(searchParams);

    const changeUrlParam = (key, v) => searchParams.set(key, v);

    const nights = differenceInDays(endDate, startDate);

    const changePeople = (params: GuestsParams) => {
        setPeopleParams(params);
    };

    // при виборі кількості дітей проставляю дефолтні роки для дітей
    const selectChildren = (value: number) => {
        const nextKidsData = [...guestsParams.kids];

        if (nextKidsData.length < value) nextKidsData.push(defaultKidsAge);
        else nextKidsData.pop();

        const params = {
            ...guestsParams,
            kids: nextKidsData,
        }
        changePeople(params)
    }

    // submit guests
    const changeGuestForm = (name: string, value: number) => {
        const params = {
            ...guestsParams,
            [name]: value,
        }

        changePeople(params)
    }

    // спрацьовує при зміні віку будь-якої дитини в списку
    const changeKidsAge = (value: any, index: number) => {
        // @ts-ignore
        const nextKidsData = [...guestsParams.kids];
        nextKidsData[index] = Number(value);

        const params = {
            ...guestsParams,
            kids: nextKidsData,
        }

        changePeople(params)
    }

    const searchHotel = data => {
        setSelectedPlaceOption(data);
    };

    const openSearchDropdown = () => {
        if (!defaultOptions.length) {
            hotelsAPI.fetchHotelsPlaces('').then((res) => {
                setDefaultOptions(formatPlacesToOptions(res.data));
            })
        }
    }

    const loadOptions = useDebounce((inputValue) => {
        //Todo: set special method for this
        return hotelsAPI
            .fetchHotelsPlaces(`?query=${inputValue}`)
            .then(res => formatPlacesToOptions(res.data));
    }, 600);

    const changeDates = dates => {
        const [start, end] = dates;

        setStartDate(start);
        setEndDate(end);
    };

    const placeObject = selectedPlaceOption.label && selectedPlaceOption.value ? selectedPlaceOption : null;

    const disabled = !startDate || !endDate || !selectedPlaceOption.value;

    const getQuery = () => {
        if (show_all && !startDate && !endDate) return '?' + new URLSearchParams(search).toString();

        return '?' + searchParams.toString();
    }

    const getUrlSearchParamsWithoutFilters = (): URLSearchParams => {
        const customSearchParams = new URLSearchParams('');
        _setParams(customSearchParams);

        return customSearchParams;
    }

    const getFiltersObjectFromQuery = () => {
        const params = Object.fromEntries(new URLSearchParams(search));
        const filters = {};

        //Todo: use lib here
        for (const [key, value] of Object.entries(params)) {
            if (key.includes('filters[')) {
                const match = key.match(/\[(.*?)\]/);
                if (match[1]) filters[match[1]] = value;
            }
        }

        return filters;
    }

    const filtersObjectFromQuery = getFiltersObjectFromQuery();

    /**
     * Get 4 map points, add it to query and search for hotels on map
     * @param mapBound
     */
    const onChangeHotelsMapView = (mapBound) => {

    }

    const isPeriod = dayjs(startDate).isAfter(dayjs('2024-12-27')) &&  dayjs(startDate).isBefore(dayjs('2025-01-05'));

    return {
        // вибір місця проживання
        place: selectedPlaceOption,
        searchHotel,
        placeObject,
        loadOptions,
        defaultOptions,

        // вибір дат
        changeDates,
        startDate,
        endDate,
        show_all,
        adults,
        startDateServerFormat: startDate ? _formatDate(startDate, DATE_FORMAT_SERVER) : '',
        endDateServerFormat: endDate ? _formatDate(endDate, DATE_FORMAT_SERVER) : '',
        nights,
        searchParams,

        // інше
        query: getQuery(),
        getQuery,
        getUrlSearchParamsWithoutFilters,
        filtersObjectFromQuery,
        changeUrlParam,
        disabled,
        openSearchDropdown,

        // гості
        guestsParams,
        orderBy: searchParams.get('order_by'),
        // спрацьвує коли вибирати кількість дітей
        selectChildren,
        // зміна форми (kids, adults)
        changeGuestForm,
        // коли міняти вік дитини
        changeKidsAge,
        //@ts-ignore
        excludeDates: (isOneOf && isPeriod) ? [new Date(dayjs(startDate).add(1, 'day'))] : []
    };
}

export default useSearchForm;
