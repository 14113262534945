import React, { useEffect, useState } from 'react';
import './OrderIndexReservationItem.scss';

import { OrderResponseReservationItem } from '../../../../../../../types/order';
import { formatDateFromString } from '../../../../../../../helpers/date';
import { DATE_FORMAT_CLIENT_SHORT, isBronui } from '../../../../../../../config';
import AppText from '../../../../../../../components/ui/typography/AppText/AppText';
import { pluralize } from '../../../../../../../helpers/translatioHelpers';
import PriceViewer from '../../../../../../../components/ui/components/PriceViewer/PriceViewer';
import FieldMaterialInput from '../../../../../../../components/ui/forms/fields/FieldMaterialInput/FieldMaterialInput';
import { createBemClassProp, getDeepDataObject } from '../../../../../../../helpers/common';
import orderApi from '../../../../../../../api/orderApi';
import ActionsDropdown from '../../../../../../../components/ui/components/ActionsDropdown/ActionsDropdown';
import { Log } from '@3ddie/react-utils';
import { useAppSelector } from '../../../../../../../hooks/reduxHooks';
import { useSelector } from 'react-redux';
import { isAuthorizedSelector } from '../../../../../../../store/auth/auth-selector';
import { useTranslation } from "react-i18next";

type Props = {
    reservation: OrderResponseReservationItem,
    index: number,
    onRemoveReservation: () => void,
    onSelectReservationForEdit: () => void
}

const getUserName = (contactData = '', profileData): string => {
    if (contactData) return contactData;

    if (profileData?.name || profileData?.lastname) {
        return `${profileData?.lastname} ${profileData?.name}`;
    }

    return '';
}

const OrderIndexReservationItem: React.FC<Props> = (props) => {
    const [isSaving, setIsSaving] = useState(false);
    const [t] = useTranslation();
    const {reservation, index, onRemoveReservation, onSelectReservationForEdit} = props;
    const arrivalDate = formatDateFromString(reservation.arrivalDate, DATE_FORMAT_CLIENT_SHORT);
    const departureDate = formatDateFromString(reservation.departureDate, DATE_FORMAT_CLIENT_SHORT);
    const cn = createBemClassProp('order-index-reservation-item');
    const profileData: any = getDeepDataObject(useAppSelector(state => state.profile));
    const isAuthorized = useSelector(isAuthorizedSelector);

    const order = useAppSelector(state => state.order)
    const {id} = order.serverOrderData?.data?.hotel?.data || {}
    const isOneOf = isBronui && ((id === 6696) || (id === 7) || (id === 4922));

    // useEffect(() => {
    //     if (!reservation?.contactData && isAuthorized) {
    //         const userName = getUserName(reservation?.contactData, profileData);
    //
    //         if (userName) onChangeContactData(userName);
    //     }
    // }, []);

    const onChangeContactData = (value: string) => {
        setIsSaving(true);
        orderApi.saveContactDataForReservation(reservation.id, value).then(() => {

        }).finally(() => {
            setIsSaving(false);
        })
    }

    return (
        <div {...cn()}>
            <div {...cn('room-name')}>
                <div>
                    <AppText variant={'gray'}>{index + 1}.&nbsp;</AppText>
                    {reservation.room}
                </div>

                <div>
                    <ActionsDropdown
                        hideEdit={isOneOf}
                        onEdit={onSelectReservationForEdit}
                        onRemove={onRemoveReservation}
                    />
                </div>
            </div>

            <div {...cn('info-text')}>
                {`${arrivalDate} - ${departureDate}`}, {reservation.mealType}
            </div>

            <div {...cn('bottom-line')}>
                <AppText variant={'gray'}>{t("Ціна за ніч", {count: Number(reservation.nights)})}</AppText>
                <PriceViewer price={reservation.amount}/>
            </div>

            {/*<Log value={profileData}/>*/}

            {isAuthorized && <div {...cn('input-container')}>
               <FieldMaterialInput
                  id={reservation.id}
                  label={t("Ім'я та прізвище гостя (необов'язково)")}
                  value={getUserName(reservation?.contactData, profileData)}
                  isLoading={isSaving}
                  disabled={!isAuthorized}
                  withEdit
                  onChangeValue={(value) => {
                      onChangeContactData(value);
                  }}
               />
            </div>}
        </div>
    )
};

export default OrderIndexReservationItem;
