import React, { FC, useEffect, useState } from 'react';
import { Element } from 'react-scroll';
import './HotelDescription.scss';
import striptags from 'striptags';

import Stars from '../../../../../components/ui/icons/hotels/Stars';
import HotelRoomsSearch from '../HotelRoomsSearch/HotelRoomsSearch';
import HotelDetailGallery from '../HotelDetailGallery/HotelDetailGallery';
import useIsOverflow from '../../../../../hooks/useIsOverflow';
import ShowMoreButton from '../ShowMoreButton/ShowMoreButton';
import HotelRoomSearchMobile from '../HotelRoomSearchMobile/HotelRoomSearchMobile';
import { Hotel } from '../../../../../types/hotel';
import { MobileOnly, TabletAndUpper } from '../../../../../components/common/Media/MediaHelpers';
import Modal from '../../../../../components/ui/modals/Modal/Modal';
import ModalLayoutPrimary
    from '../../../../../components/ui/modals/Modal/components/ModalLayoutPrimary/ModalLayoutPrimary';
import HotelDetailUserActions from '../HotelDetailUserActions/HotelDetailUserActions';
import HotelBookSection from '../HotelBookSection/HotelBookSection';
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { isBronui } from "../../../../../config";
import { useHistory, useLocation } from "react-router-dom";
import { getDifferenceInNights } from "../../../../../helpers/date";
import { updateQueryStringParameter } from "../../../../../helpers/url";

interface Props {
    hotel: Hotel
}

const HotelDescription: FC<Props> = ({hotel}) => {
    const {
        id,
        isFavorite,
        address,
        name,
        photo,
        photos,
        description,
        stars = 0,
        accommodationType,
        placeName,
        distanceToPlaceFormatted
    } = hotel;
    const [t] = useTranslation();
    const [showMore, setShowMore] = useState(false);
    const [showMoreButton, setShowMoreButton] = useState(false);
    const ref = React.useRef();
    const history = useHistory();
    const location = useLocation();
    const [isNotifyOpen, setNotifyOpen] = useState(false);


    // TODO Ніколи не робіть перевірку на ід, виносити в налаштування адмінки


    const isOneOf = isBronui && ((hotel.id === 6696) || (hotel.id === 7) || (hotel.id === 4922));



    useEffect(()=>{
        // console.log("hotel.id",hotel.id, hotel)
        // Якщо в датах готелю різниця тільки 1 доба, тоді змінювати дату виїзду на день пізніше і показувати модалку
        // arrival_date
        //
        const params = new URLSearchParams(location.search)
        const arrival_date = params.get('arrival_date')
        const departure_date = params.get('departure_date')

        const arrival_date_period = arrival_date ? arrival_date :  dayjs(new Date()).format('YYYY-MM-DD')
        const isPeriod = dayjs(arrival_date_period).isAfter(dayjs('2024-12-27')) &&  dayjs(arrival_date_period).isBefore(dayjs('2025-01-05'));

        if(isOneOf && isPeriod){


            // якщо є дати і бронювання тільки на 1 день
            if(arrival_date && departure_date && getDifferenceInNights(arrival_date, departure_date) <= 1 ){
                const a = dayjs(departure_date)
                const b = a.add(1, 'day')

                const queryParams = updateQueryStringParameter(location.search, 'departure_date', b.format('YYYY-MM-DD'));
                history.push(`${location.pathname}${queryParams}`)
                setNotifyOpen(true)
            }

            // якщо немає дати тоді додати + 1 день
            if(!params.get('departure_date')){
                const a = dayjs(new Date())
                const b = a.add(2, 'day')
                history.push(`${location.pathname}?arrival_date=${a.format('YYYY-MM-DD')}&departure_date=${b.format('YYYY-MM-DD')}`)
                setNotifyOpen(true)
            }

        }

    },[hotel.id])


    useIsOverflow(ref, (isOverflow: boolean) => {
        setShowMoreButton(isOverflow);
    });
    const title = <h1 className="hotel-description__name">{name}</h1>;

    const titleAddressRow = (
        <div className="hotel-description__block-stars-address">
            <div className="hotel-description__top-row">
                {stars ? <div className="hotel-description__top-row-stars-wrapper"><Stars stars={stars}/></div> : null}

                {/*<AppMedia.Media at='sm' className={'hotel-description__top-row-texts-wrapper'}>*/}
                {/*    <span className="hotel-description__top-row-texts">*/}
                {/*        /!*<span>Відгуки • {reviewsCount}</span>*!/*/}

                {/*        <span>{distanceToPlaceFormatted}</span>*/}
                {/*    </span>*/}
                {/*</AppMedia.Media>*/}

                <div className="hotel-description__info-texts">
                    {accommodationType?.data?.name && (
                        <div className={'hotel-description__accommodation-content'}>
                            {accommodationType?.data?.icon && <img src={accommodationType?.data?.icon} alt={''}/>}
                            <span className="hotel-description__info-text">{accommodationType?.data?.name}</span>
                        </div>
                    )}

                    <TabletAndUpper>
                        <span className="hotel-description__info-text">
                            {t('Адреса')}: {address}
                        </span>
                    </TabletAndUpper>

                    {(distanceToPlaceFormatted || placeName) && (
                        <span className="hotel-description__info-text hotel-description__info-text--with-dot">
                            {distanceToPlaceFormatted}{placeName && `, ${placeName}`}
                        </span>
                    )}
                </div>
            </div>
        </div>
    );



    return (
        <div className="hotel-description">

            {isNotifyOpen && <Modal className="modal-favorite-share" onClose={()=> setNotifyOpen(false)}>
                <ModalLayoutPrimary title={hotel?.name}>
                    <div>
                        {t('min_days_book')}
                        <br/>
                    </div>
                </ModalLayoutPrimary>
            </Modal>}
            <div className="hotel-description__header">
                <MobileOnly>
                    <HotelDetailUserActions
                        hotelId={id}
                        isFavorite={isFavorite}
                        name={name}
                        hotelPhoto={photo?.source?.['302x170']}
                    />
                </MobileOnly>

                <TabletAndUpper>
                    {title}

                    <div className="hotel-description__header-info-row">
                        {titleAddressRow}

                        <HotelDetailUserActions
                            hotelId={id}
                            isFavorite={isFavorite}
                            name={name}
                            hotelPhoto={photo?.source?.['302x170']}
                        />
                    </div>
                </TabletAndUpper>

                <HotelDetailGallery photos={photos} hotelAlias={hotel.alias}/>

                <MobileOnly>
                    {title}
                    {titleAddressRow}
                </MobileOnly>
            </div>

            <div className="hotel-description__description-and-form-row">
                <div className="hotel-description__description-wrap">
                    <div className={`hotel-description__description`} ref={ref}>
                        <MobileOnly>
                            {striptags(description)}
                        </MobileOnly>

                        <TabletAndUpper>
                            <div dangerouslySetInnerHTML={{__html: description}}/>
                        </TabletAndUpper>
                    </div>

                    {(showMoreButton) ? (
                        <div className={`hotel-description__show-more-wrapper ${showMore ? 'is-open-more' : ''}`}>
                            <ShowMoreButton
                                onClick={() => setShowMore(!showMore)}
                                isShowingMore={showMore}
                            />
                        </div>
                    ) : null}
                </div>

                {showMore && (
                    <Modal
                        className={'hotel-description-about-modal'}
                        onClose={() => setShowMore(false)}
                    >
                        <ModalLayoutPrimary title={t("Про цей готель")}>
                            <h3 className={'hotel-description-about-modal__title'}>{t("Про готель")} {name}</h3>
                            <div className={'hotel-description-about-modal__text'}>
                                <div dangerouslySetInnerHTML={{__html: description}}/>
                            </div>
                        </ModalLayoutPrimary>
                    </Modal>
                )}

                <Element name='hotelDetailSearchForm'>
                    <MobileOnly>
                        <HotelRoomSearchMobile hotelName={hotel?.name}  isOneOf={isOneOf} />
                        {/*<HotelBookSection isMobile/>*/}
                    </MobileOnly>



                    <TabletAndUpper>
                        <HotelRoomsSearch  isOneOf={isOneOf}/>
                    </TabletAndUpper>
                </Element>
            </div>
        </div>
    );
};

// const nextDay = new Date(dayjs(startDate).add(1, 'day'));
export default React.memo(HotelDescription);
