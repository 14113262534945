import React, { FC, useRef } from 'react';
import './SearchModalDates.scss';
import ModalMobile from '../../../../../components/ui/modals/ModalMobile/ModalMobile';
import { formatDate } from '../../../../../helpers/date';
import FieldDatepicker from '../../../../../components/ui/forms/fields/FieldDatepicker/FieldDatepicker';
import IconArrowLeft from '../../../../../components/ui/icons/account/IconArrowLeft';
import ModalMobileHeader from '../../../../../components/ui/modals/ModalMobile/components/ModalMobileHeader/ModalMobileHeader';
import ModalMobileButtons from '../../../../../components/ui/modals/ModalMobile/components/ModalMobileButtons/ModalMobileButtons';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

type DateType = Date | null;

interface Props {
   onCloseModal: () => void;
   onSubmit: (values: Array<DateType>) => void;
   changeDates: (values: Array<DateType>) => void;
   start: Date | null;
   end: Date | null;
   title?: string;
   selectsDisabledDaysInRange?: boolean;
    excludeDates?: Array<Date>;
}

const getDate = date => (date ? `${formatDate(new Date(date), 'dd MMM')}` : '');

const SearchModalDates: FC<Props> = props => {
   const { onCloseModal, start, end, onSubmit, changeDates, title, ...rest } = props;
   const calendarRef = useRef(null);
   const [t] = useTranslation();

   const onSubmitDates = () => {
      onSubmit([start, end]);
   };

   const clearData = () => {
      changeDates([null, null]);
   };

   return (
      <div>
         <ModalMobile
            className="mobile-modal-calendar"
            Footer={() => {
               return (
                  <ModalMobileButtons
                     cancel={clearData}
                     submit={onSubmitDates}
                     disabled={!end || !start}
                     buttonText={t('Вибрати дати')}
                  />
               );
            }}
            Header={() => {
               const period = getDate(start) && getDate(end) && `${getDate(start)} - ${getDate(end)}`;
               return (
                  <div className="mobile-modal-calendar__header">
                     <ModalMobileHeader
                        title={title || ''}
                        description={period}
                        onClickIcon={onCloseModal}
                        icon={<IconArrowLeft />}
                     />


                    {rest.excludeDates && rest.excludeDates.length ?
                      <div className="mobile-modal-calendar__notify">
                        {t('min_days_book')}
                      </div>
                    : null }

                     {/*<h4 className={'mobile-modal-calendar__dates-title'}>*/}
                     {/*    {t('Додайте дати')}*/}
                     {/*</h4>*/}

                     <div className="react-datepicker__day-names">
                        {[t('пн'), t('вт'), t('ср'), t('чт'), t('пт'), t('сб'), t('нд')].map(label => (
                           <div className="react-datepicker__day-name" key={'day-' + label}>
                              {t(label)}
                           </div>
                        ))}
                     </div>
                  </div>
               );
            }}
         >
            <div className="mobile-modal-calendar__body">
               <FieldDatepicker
                  // @ts-ignore
                  onChange={changeDates}
                  //@ts-ignore
                  ref={calendarRef}
                  selectsRange
                  disabledKeyboardNavigation
                  shouldCloseOnSelect={false}
                  selected={null}
                  minDate={new Date()}
                  startDate={start}
                  endDate={end}
                  inline
                  monthsShown={12}
                  selectsDisabledDaysInRange
                  {...rest}
               />
            </div>
         </ModalMobile>
      </div>
   );
};

export default SearchModalDates;
